export default {
  '/user/company': {
    showAdd: false,
    showDel: false,
    showEdit: false,
    showDetail: true,
    showSearch: true,
    routerData: null,
    listData: null,
    name: 'VIP价格管理',
    apiUrl: '/UserCompany',
    listRouter: '/user/company',
    editRouter: '/user/company/edit',
    addRouter: '/user/company/edit',
    delRouter: '/user/company/del',
    detailRouter: '/user/company/detail',
    list: {
      com: '',
      params: [
        {name: '企业名称', value: 'company_name'},
        {name: '创建人', value: 'create_user.username',root:true},
        {name: '统一社会信用编号', value: 'company_code'},
        {name: '营业执照', value: 'files_ids',type:'image'},
        {name: '状态', value: 'is_check', type: 'custom-select',options:[{label:'待审核',val:0,color:'warning'},{label:'审核通过',val:1,color:'success'},{label:'审核不通过',val:2,color:'danger'}]},
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'time'},
      ],
      extButtons:[
        {label:'审核',type:'shenpi',rules:{key:'is_check',val:0},button_type:'primary',url:'/UserCompany/toShenpi',column:{stateKey:'is_check',reasonKey:'reason'}}
      ]
    },
    edit: {
      com: '',
      params: [
      ]
    },
    add: {
      com: '',
    },
    detail: {
      com: '',
      params: [
        {name: '状态', value: 'is_check', type: 'custom-select',options:[{label:'待审核',value:0,color:'warning'},{label:'审核通过',value:1,color:'success'},{label:'审核不通过',value:2,color:'danger'}]},
        {name: '企业名称', value: 'company_name'},
        {name: '创建人', value: 'create_user.username',root:true},
        {name: '统一社会信用编号', value: 'company_code'},
        {name: '营业执照', value: 'files_ids',type:'image',root:true},
        {name: '创建时间', value: 'create_time'},
        {name: '修改时间', value: 'update_time', type: 'timeDate'},
      ]
    },
    search: {
      params: [
        {name: '企业名称', value: 'company_name'},
        {name: '社会信用编号', value: 'company_code'},
        {name: '审核状态', value: 'is_check',type:'select',def:'0',options:[{name:'待审核',id:'0'},{name:'审核通过',id:'1'},{name:'审核不通过',id:'2'}]},
      ]
    }
  },
}
