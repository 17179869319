<template>
    <div class="bg-light padding-20" v-if="formData && formData.user">
        <el-form :model="formData" ref="form" label-width="150px">
            <el-form-item label="ID" v-if="formData.id">
                {{formData.id}}
            </el-form-item>
            <el-form-item label="用户名">{{formData.username}}</el-form-item>
            <el-form-item label="手机号">
                <el-input v-model="formData.mobile" style="width: 500px;"/>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="formData.password" style="width: 500px;"/>
                如果不修改密码，则留空
            </el-form-item>
            <el-form-item label="邮箱">
                <el-input v-model="formData.user.email" style="width: 500px;"/>
            </el-form-item>
            <el-form-item label="姓名">
                <el-input v-model="formData.user.name" style="width: 500px;"/>
            </el-form-item>
            <el-form-item label="公司">
                <el-input v-model="formData.user.company" style="width: 500px;"/>
            </el-form-item>
            <el-form-item label="职位">
                <el-input v-model="formData.user.job" style="width: 500px;"/>
            </el-form-item>
            <el-form-item :label="item.title" prop="vip_end_time" v-for="(item,index) in formData.vip_list">
                <push-time clearable v-model="formData.vip_list[index].vip_end_time" style="width: 500px;"></push-time>
            </el-form-item>
            <el-form-item label="是否禁用">
                <el-switch
                    v-model="formData.state"
                    active-color="#13ce66"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="正常"
                    inactive-text="禁用">
                </el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保存</el-button>
                <el-button @click="$router.back()">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "edit",
    data(){
        return{
            formData:{},
        }
    },
    created() {
        this.formData.id = this.$route.query.id ? this.$route.query.id : '';
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail() {
            let _this = this;
            this.$http.httpPost({
                url: '/User/detail',
                datas: { id: this.formData.id },
                success(res) {
                    _this.formData = res ? res : null;

                    if(_this.formData) {
                        _this.formData.password = '';
                    }
                }
            });
        },
        create() {
            let _this = this;
            this.$http.httpPost({
                url: '/User/edit',
                datas: this.formData,
                success(res) {
                    _this.$utils.success('保存成功！');
                    _this.$router.back();
                }
            });
        },
        submitForm(formName) {
            //存在回调的方法，因为回调外和回调内的作用域不一致，所以务必为 _this 复制 this，否则在回调中无法访问this对象
            let _this = this;
            console.log(this.formData);
            //通过传递form表单ref名称，来调用表单的validate方法
            this.$refs[formName].validate(valid => {
                //为true时验证通过
                if (valid) {
                    _this.create();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        change(e){
            if(e === 0){
                this.formData.vip_end_time = '';
            }
        }
    }
}
</script>
