import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/site/Login";

import BaseList from '@/views/base/list';
import BaseEdit from '@/views/base/edit';
import BaseDetail from '@/views/base/detail';

import Policy from "@/views/policy/list";
import TypeList from '@/views/type/list';

import SettingAbout from '@/views/setting/about';
import SettingAbout2 from '@/views/setting/about2';

import BaiqiangList from '@/views/baiqiang/list';

import SettingPay from '@/views/setting/pay';
import SettingService from '@/views/setting/service';
import SettingFuwu from '@/views/setting/fuwu';
import SettingMini from '@/views/setting/mini';
import SettingBlock from '@/views/setting/block';
import SettingMail from '@/views/setting/mail';
import SettingBase from "@/views/setting/base";

import OrderList from '@/views/order/list';
import OrderList2 from '@/views/order/list2';
import OrderDetail from '@/views/order/detail';
import DashBoard from "@/views/site/DashBoard";

import ReplyList from '@/views/reply/list';
import changePass from "@/views/site/changePass";
import BaseConfig from "@/BaseConfig";
import CalculatorBase from "@/views/calculator/base";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/dashboard",
    component: Home,
    children: [
      // {
      //   path: "/policy/:type",
      //   name: "home",
      //   component: Policy
      // },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashBoard,
      },
      {
        path: '/changePass',
        name: 'changePass',
        component: changePass,
      },
      // {
      //   path: "/type/:type",
      //   name: "home",
      //   component: TypeList,
      //   meta:{title:'字典管理'}
      // },
      {
        path: "/about/des",
        name: "SettingAbout",
        component: SettingAbout,
        meta:{title:'协会简介'}
      },
      {
        path: "/about2/des",
        name: "SettingAbout2",
        component: SettingAbout2,
        meta:{title:'协会简介'}
      },
      {
        path: '/calculator/base',
        name: 'CalculatorBase',
        component: CalculatorBase,
        meta:{title:'固定参数设置'}
      },
      {
        path: '/setting/base',
        name: 'SettingBase',
        component: SettingBase,
        meta:{title:'基础设置'}
      },
      {
        path: '/setting/pay',
        name: 'SettingPay',
        component: SettingPay,
        meta:{title:'支付设置'}
      },
      {
        path: '/setting/service',
        name: 'SettingService',
        component: SettingService,
        meta:{title:'用户协议'}
      },
      {
        path: '/setting/fuwu',
        name: 'SettingFuwu',
        component: SettingFuwu,
        meta:{title:'服务号设置'}
      },
      {
        path: '/setting/mini',
        name: 'SettingMini',
        component: SettingMini,
        meta:{title:'小程序设置'}
      },
      {
        path: '/setting/block',
        name: 'SettingBlock',
        component: SettingBlock,
        meta:{title:'模块设置'}
      },
      {
        path: '/setting/mail',
        name: 'SettingMail',
        component: SettingMail,
        meta:{title:'邮件设置'}
      },
      {
        path: '/order/list',
        name: 'OrderList',
        component: OrderList,
        meta:{title:'订单记录'}
      },
      {
        path: '/order/list2',
        name: 'OrderList2',
        component: OrderList2,
        meta:{title:'支付记录'}
      },
      {
        path: '/order/detail/:id',
        name: 'OrderDetail',
        component: OrderDetail
      },
      {
        path: '/reply',
        name: 'ReplyList',
        component: ReplyList,
        meta:{title:'评论管理'}
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

let _paths = BaseConfig;

for(let i in _paths){

  let _listRouter = _paths[i].listRouter;
  let _editRouter = _paths[i].editRouter;
  let _addRouter = _paths[i].addRouter;
  // let _path = i;
  //
  // if(_paths[i].router){
  //   _path = _paths[i].router;
  // }

  // console.log(_paths[i].name);

  // console.log(_paths[i].list.com);

  console.log(_paths[i].list.com);
  let _listPath = {
    path:_listRouter,
    name:i+'List',
    component:_paths[i].list.com ? () => import('@/views' + _paths[i].list.com) : BaseList,
    meta:{title:_paths[i].name}
  }

  routes[0].children.push(_listPath);

  if(_paths[i].editRouter){
    let _editPath = null;
    _editPath = {
      path: _editRouter,
      name: i + 'Edit',
      component: _paths[i].edit.com ? () => import('@/views' + _paths[i].edit.com) : BaseEdit,
      meta: {title: '保存' + _paths[i].name}
    }
    routes[0].children.push(_editPath);
  }
  
  if(_paths[i].addRouter){
    let _addPath = null;
    _addPath = {
      path: _addRouter,
      name: i + 'Add',
      component: _paths[i].add.com ? () => import('@/views' + _paths[i].add.com) : BaseEdit,
      meta: {title: '保存' + _paths[i].name}
    }
    routes[0].children.push(_addPath);
  }

  if(_paths[i].detailRouter){
    let _detailPath = {
      path:_paths[i].detailRouter,
      name:i+'Edit',
      component:_paths[i].detail && _paths[i].detail.com ? () => import('@/views' + _paths[i].detail.com) : BaseDetail,
      meta:{title:_paths[i].name+'详情'}
    }

    routes[0].children.push(_detailPath);
  }

  if(_paths[i].list.extButtons){

    for(let i2 =0 ;i2 < _paths[i].list.extButtons.length;i2++){
      // if(_paths[i].list.extButtons[i2].router) {
      //   let _extPath = {
      //     path: _paths[i].list.extButtons[i2].path,
      //     name: _paths[i].list.extButtons[i2].name,
      //     component: () => import('@/views' + _paths[i].list.extButtons[i2].path),
      //     meta: {title: _paths[i].list.extButtons[i2].title}
      //   }
      //
      //   routes[0].children.push(_extPath);
      // }

      if(_paths[i].list.extButtons[i2].type !== 'shenpi' && _paths[i].list.extButtons[i2].type !== 'changeState') {
        let _extPath = {
          path: _paths[i].list.extButtons[i2].path,
          name: _paths[i].list.extButtons[i2].name,
          component: _paths[i].list.extButtons[i2].router ? () => import('@/views' + _paths[i].list.extButtons[i2].path) : BaseList,
          meta: {title: _paths[i].list.extButtons[i2].title}
        }
  
        routes[0].children.push(_extPath);
      }
    }
  }
}

console.log(routes);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
