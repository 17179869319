export default [
  {
    title:'首页',
    path: '/dashboard',
    icon:'el-icon-tickets',
  },
  {
    title:'字典管理',
    path: '#',
    icon:'el-icon-tickets',
    child:[
      {title:'机构部门',path:'/type/department',query:null},
      {title:'政策分类',path:'/dict/policy',query:null},
      {title:'标准分类',path:'/dict/standard',query:null},
      // {title:'企业性质',path:'/type/nature',query:null},
      {title:'企业性质',path:'/dict/nature',query:null},
      {title:'企业属性',path:'/dict/property',query:null},
	    {title:'企业人员规模',path:'/type/person',query:null},
      {title:'产品类型',path:'/type/product',query:null},
      {title:'细分报告分类',path:'/dict/domainReport',query:null},
      {title:'资讯分类',path:'/dict/news',query:null},
      {title:'奖项分类',path:'/dict/jiangxiang',query:null},
      // {title:'奖项类型',path:'/type/survey',query:null},
      // {title:'问答分类',path:'/type/question',query:null},
    ]
  },
  {
    title:'新闻资讯',
    path: '/news11',
    icon:'el-icon-tickets',
    child:[
      {title:'资讯列表',path:'/news',query:null},
      {title:'草稿箱',path:'/news/ready',query:null},
    ]
  },
  // {
  //   title:'企业数据库',
  //   path: '#00',
  //   icon:'el-icon-tickets',
  //   child:[
  //     {title:'重点企业',path:'/baiqiang',query:null},
  //     {title:'奖项企业',path:'/company',query:null},
  //   ]
  // },
  {
    title:'奖项',
    path: '#00',
    icon:'el-icon-tickets',
    child:[
      {title:'奖项管理',path:'/prize',query:null},
      {title:'企业库',path:'/company',query:null},
    ]
  },
  {
    title:'轮播管理',
    path: '/banner',
    icon:'el-icon-tickets',
    query:null
  },
  // {
  //   title:'重点企业',
  //   path: '/baiqiang',
  //   icon:'el-icon-tickets',
  // },
  {title:'政策标准',path:'#1',query:null,icon:'el-icon-present',
    child:[
      {title:'政策管理',path:'/policy/policy',query:null},
      {title:'政策草稿箱',path:'/policy/policy/draft',query:null},
      {title:'标准管理',path:'/policy/standard',query:null},
      {title:'标准草稿箱',path:'/policy/standard/draft',query:null},
    ]
  },
  {title:'研究报告',path:'#2',query:null,icon:'el-icon-present',
    child:[
      {title:'行业发展报告',path:'/report/industry',query:null},
      {title:'细分领域报告',path:'/report/domain',query:null},
    ]
  },
  {title:'冷库计算器',path:'/calculator',icon:'el-icon-present',child:[
      {title:'地区默认参数设置',path:'/calculator/type',query:null},
      {title:'固定参数设置',path:'/calculator/base',query:null},
    ]
  },
  {title:'行业数据-宏观冷链',path:'/hydata/lenglian',icon:'el-icon-present',child:[
      {title:'季度',path:'/hydata/lenglian/quarter',query:{time_type:'quarter'}},
      {title:'年度',path:'/hydata/lenglian/year',query:{time_type:'year'}},
    ]
  },
  // {title:'行业数据-冷链设备',path:'/hydata/lenglian/shebei',icon:'el-icon-present',child:[
  //     {title:'季度',path:'/hydata/lenglian/shebei/quarter',query:{time_type:'quarter'}},
  //     {title:'年度',path:'/hydata/lenglian/shebei/year',query:{time_type:'year'}},
  //   ]
  // },
  {title:'行业数据-冷链设备',path:'/hydata/lenglian/shebei/year',icon:'el-icon-present'},
  {title:'行业数据-冷藏车',path:'#7',query:null,icon:'el-icon-present',
    child:[
      // {title:'保有量',path:'/hydata/car/count',query:null},
      // {title:'销量-地区',path:'/hydata/car/area',query:null},
      // {title:'销量-车型',path:'/hydata/car/type',query:null},
      // {title:'销量-燃料',path:'/hydata/car/oil',query:null},
      {title:'保有量',path:'/hydata/car/count',query:null,child:[
          // {title:'月度',path:'/hydata/car/count/month',query:{time_type:'month'}},
          {title:'季度',path:'/hydata/car/count/quarter',query:{time_type:'quarter'}},
          // {title:'半年度',path:'/hydata/car/count/half',query:{time_type:'half'}},
          {title:'年度',path:'/hydata/car/count/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-车型分布',path:'/hydata/car/type',query:null,child:[
          // {title:'月度',path:'/hydata/car/type/month',query:{time_type:'month'}},
          {title:'季度',path:'/hydata/car/type/quarter',query:{time_type:'quarter'}},
          // {title:'半年度',path:'/hydata/car/type/half',query:{time_type:'half'}},
          {title:'年度',path:'/hydata/car/type/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-燃料分布',path:'/hydata/car/oil',query:null,child:[
          // {title:'月度',path:'/hydata/car/oil/month',query:{time_type:'month'}},
          {title:'季度',path:'/hydata/car/oil/quarter',query:{time_type:'quarter'}},
          // {title:'半年度',path:'/hydata/car/oil/half',query:{time_type:'half'}},
          {title:'年度',path:'/hydata/car/oil/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-地区分布',path:'/hydata/car/area',query:null,child:[
          // {title:'月度',path:'/hydata/car/area/month',query:{time_type:'month'}},
          {title:'季度',path:'/hydata/car/area/quarter',query:{time_type:'quarter'}},
          // {title:'半年度',path:'/hydata/car/area/half',query:{time_type:'half'}},
          {title:'年度',path:'/hydata/car/area/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-省份分布',path:'/hydata/car/province',query:null,child:[
          // {title:'月度',path:'/hydata/car/area/month',query:{time_type:'month'}},
          {title:'季度',path:'/hydata/car/province/quarter',query:{time_type:'quarter'}},
          // {title:'半年度',path:'/hydata/car/area/half',query:{time_type:'half'}},
          {title:'年度',path:'/hydata/car/province/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-厂商分布',path:'/hydata/car/supplier',query:null,child:[
          {title:'季度',path:'/hydata/car/supplier/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/car/supplier/year',query:{time_type:'year'}},
        ]
      },
      {title:'销量-新能源厂商分布',path:'/hydata/car/supplierxny',query:null,child:[
          {title:'季度',path:'/hydata/car/supplierxny/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/car/supplierxny/year',query:{time_type:'year'}},
        ]
      },
      // {title:'车型销量',path:'/hydata/car/type',query:null},
      // {title:'燃料销量',path:'/hydata/car/oil',query:null},
      // {title:'销量地区分布',path:'/hydata/car/area',query:null},
    ]
  },
  {title:'行业数据-冷库',path:'#8',query:null,icon:'el-icon-present',
    child:[
      {title:'库容/容积',path:'/hydata/lengku/amout',query:null,child:[
          {title:'季度',path:'/hydata/lengku/amout/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/amout/year',query:{time_type:'year'}},
        ]
      },
      {title:'供需',path:'/hydata/lengku/gongxu',query:null,child:[
          {title:'季度',path:'/hydata/lengku/gongxu/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/gongxu/year',query:{time_type:'year'}},
        ]
      },
      {title:'市场-空置率',path:'/hydata/lengku/shichang/vacancy',query:null,child:[
          {title:'季度',path:'/hydata/lengku/shichang/vacancy/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/shichang/vacancy/year',query:{time_type:'year'}},
        ]
      },
      {title:'市场-价格',path:'/hydata/lengku/shichang/price',query:null,child:[
          {title:'季度',path:'/hydata/lengku/shichang/price/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/shichang/price/year',query:{time_type:'year'}},
        ]
      },
      {title:'建设',path:'/hydata/lengku/build',query:null,child:[
          {title:'季度',path:'/hydata/lengku/build/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/build/year',query:{time_type:'year'}},
        ]
      },
      {title:'在建项目',path:'/hydata/lengku/project',query:null,child:[
          {title:'季度',path:'/hydata/lengku/project/quarter',query:{time_type:'quarter'}},
          {title:'年度',path:'/hydata/lengku/project/year',query:{time_type:'year'}},
        ]
      },
    ]
  },
  {title:'行业数据-产量',path:'#6',query:null,icon:'el-icon-present',
    child:[
      {title:'产量',path:'/hydata/chanliang/#1',query:null,child:[
          {title:'水果',path:'/hydata/chanliang/shuiguo/year',query:{time_type:'year'}},
          {title:'蔬菜',path:'/hydata/chanliang/shucai/year',query:{time_type:'year'}},
          {title:'肉类',path:'/hydata/chanliang/rouzhipin/year',query:{time_type:'year'}},
          {title:'水产品',path:'/hydata/chanliang/shuichanpin/year',query:{time_type:'year'}},
          {title:'乳制品',path:'/hydata/chanliang/ruzhipin/year',query:{time_type:'year'}},
          {title:'速冻品',path:'/hydata/chanliang/sudong/year',query:{time_type:'year'}},
        ]
      },
      {title:'人均农食消费',path:'/hydata/chanliang/cost/year',query:{time_type:'year'}},
    ]
  },
  {title:'行业数据-进出口',path:'/hydata/inout',query:null,icon:'el-icon-present',
    child:[
      {title:'总值',path:'/hydata/inout/#1',query:null,child:[
          {title:'月度',path:'/hydata/inout/month',query:{time_type:'month'}},
          {title:'年度',path:'/hydata/inout/year',query:{time_type:'year'}},
        ]
      },
      {title:'品类',path:'/hydata/inout/#2',query:null,child:[
          {title:'月度',path:'/hydata/inout/type/month',query:{time_type:'month'}},
          {title:'年度',path:'/hydata/inout/type/year',query:{time_type:'year'}},
        ]
      },
      {title:'国别',path:'/hydata/inout/#3',query:null,child:[
          {title:'蔬菜及制品进口',path:'/hydata/inout/country/shucai/year',query:{time_type:'year',type:'shucai'}},
          {title:'水果及制品进口',path:'/hydata/inout/country/shuiguo/year',query:{time_type:'year',type:'shuiguo'}},
          {title:'肉类及制品进口',path:'/hydata/inout/country/rou/year',query:{time_type:'year',type:'rou'}},
          {title:'水海产品及制品进口',path:'/hydata/inout/country/shuichanpin/year',query:{time_type:'year',type:'shuichanpin'}},
          {title:'乳制品进口',path:'/hydata/inout/country/ruzhipin/year',query:{time_type:'year',type:'ruzhipin'}},
        ]
      },
      {title:'口岸',path:'/hydata/inout/kouan',query:null},
      // {title:'国别',path:'/hydata/inout/country/year',query:{time_type:'year'}},
      // {title:'出口',path:'/hydata/inout/out',query:null,child:[
      //     // {title:'月度',path:'/hydata/inout/out/month',query:{time_type:'month'}},
      //     {title:'季度',path:'/hydata/inout/out/quarter',query:{time_type:'quarter'}},
      //     // {title:'半年度',path:'/hydata/inout/out/half',query:{time_type:'half'}},
      //     {title:'年度',path:'/hydata/inout/out/year',query:{time_type:'year'}},
      //   ]
      // },
      // // {title:'出口',path:'/hydata/inout/out',query:null},
      // {title:'主要口岸',path:'/hydata/car/oil',query:null},
    ]
  },
  // {title:'行业数据-冷库',path:'/hydata/lengku',icon:'el-icon-present',query:null},
  // {title:'行业数据',path:'#3',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'冷库',path:'/hydata/lengku',query:null},
  //     {title:'冷链规模',path:'/hydata/lenglian',query:null},
  //     {title:'特色农产品',path:'/hydata/product',query:null},
  //   ]
  // },
  // {title:'农批市场',path:'#5',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'市场概况',path:'/hydata/nongpi',query:null},
  //     {title:'市场分布情况',path:'/hydata/nongpi/area',query:null},
  //   ]
  // },
  // {title:'产量',path:'#6',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'水果',path:'/hydata/chanliang/shuiguo',query:null},
  //     {title:'蔬菜',path:'/hydata/chanliang/shucai',query:null},
  //     {title:'肉类',path:'/hydata/chanliang/rou',query:null},
  //     {title:'水产品',path:'/hydata/chanliang/shuichanpin',query:null},
  //     {title:'乳制品',path:'/hydata/chanliang/ruzhipin',query:null},
  //     {title:'速冻品',path:'/hydata/chanliang/sudong',query:null},
  //   ]
  // },
  // {title:'展示柜',path:'#9',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'按年份',path:'/hydata/year/zhanshigui',query:null},
  //     {title:'按企业',path:'/hydata/company/zhanshigui',query:null},
  //     // {title:'按月份',path:'/hydata/month/zhanshigui',query:null},
  //   ]
  // },
  // {
  //   title:'用户管理',
  //   path: '/user',
  //   icon:'el-icon-tickets',
  // },
  {title:'用户管理',path:'#11',query:null,icon:'el-icon-present',
    child:[
      {title:'会员管理',path:'/user',query:null},
      {title:'VIP价格管理',path:'/user/vip',query:null},
      {title:'企业管理',path:'/user/company',query:null},
    ]
  },
  {
    title:'提现管理',
    path: '/cash/list',
    icon:'el-icon-tickets',
  },
  // {
  //   title:'问答管理',
  //   path: '/question',
  //   icon:'el-icon-tickets',
  // },
  // {
  //   title:'奖项申报',
  //   path: '/survey',
  //   icon:'el-icon-tickets',
  // },
  // {
  //   title:'权威榜单',
  //   path: '/prize',
  //   icon:'el-icon-tickets',
  // },
  // {
  //   title:'奖项评选',
  //   path: '/prize',
  //   icon:'el-icon-tickets',
  //   child:[
  //     {title:'医药奖项',path:'/prize/medicine',query:null},
  //     {title:'医疗器械奖项',path:'/prize/apparatus',query:null},
  //   ]
  // },
  // {title:'留言管理',path:'#12',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'留言管理',path:'/messageboard',query:null},
  //     {title:'屏蔽关键词',path:'/badword/message',query:null},
  //   ]
  // },
  // {
  //   title:'留言管理',
  //   path: '/messageboard',
  //   icon:'el-icon-tickets',
  // },
  {
    title:'评论管理',
    path: '/reply',
    icon:'el-icon-tickets',
  },
  {
    title:'发票管理',
    path: '/user/invoice/log',
    icon:'el-icon-tickets',
  },
  {title:'订单管理',path:'#13',query:null,icon:'el-icon-present',
    child:[
      {title:'订单记录',path:'/order/list',query:null},
      {title:'支付记录',path:'/order/list2',query:null},
    ]
  },
  {
    title:'短信记录',
    path: '/sms',
    icon:'el-icon-tickets',
  },
  {
    title:'成员管理',
    path: '/member',
    icon:'el-icon-tickets',
  },
  // {
  //   title:'VIP价格管理',
  //   path: '/user/vip',
  //   icon:'el-icon-tickets',
  // },
  {title:'系统设置',path:'#14',query:null,icon:'el-icon-present',
    child:[
      {title:'基础设置',path:'/setting/base',query:null},
      {title:'菜单设置',path:'/setting/menu',query:null},
      {title:'邮件设置',path:'/setting/mail',query:null},
      {title:'支付设置',path:'/setting/pay',query:null},
      {title:'短信设置',path:'/setting/sms',query:null},
      {title:'服务号设置',path:'/setting/fuwu',query:null},
      {title:'小程序设置',path:'/setting/mini',query:null},
      {title:'用户协议',path:'/setting/service',query:null},
      {title:'模块设置',path:'/setting/block',query:null},
      {title:'模块权限设置',path:'/setting/block/vip',query:null},
      {title:'地区设置',path:'/divisions',query:null},
    ]
  },
  {title:'冷链关于我们',path:'#10',query:null,icon:'el-icon-present',
    child:[
      {title:'协会简介',path:'/about/des',query:null},
      {title:'会员展示',path:'/about/kehu',query:null},
      {title:'我们的团队',path:'/about/team',query:null},
      {title:'协会大事记',path:'/about/history',query:null},
    ]
  },
  // {title:'农产品关于我们',path:'#15',query:null,icon:'el-icon-present',
  //   child:[
  //     {title:'协会简介',path:'/about2/des',query:null},
  //     {title:'会员展示',path:'/about2/kehu',query:null},
  //     {title:'我们的团队',path:'/about2/team',query:null},
  //     {title:'协会大事记',path:'/about2/history',query:null},
  //   ]
  // },
];
